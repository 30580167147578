<template>
<!--    <div class="categories__content content__grid">-->
<!--        <div class="categories__content-item content__grid-item">-->
<!--            <a :href="categoryLink + '/-1'" class="categories__content-link content__grid-link">-->
<!--                <h4 class="categories__content-title content__grid-title">{{ showAll }}</h4>-->
<!--                <div style="text-align:center">-->
<!--                    <img src="/images/logo.svg" alt="JVB" style="max-width: 140px">-->
<!--                </div>-->
<!--            </a>-->
<!--        </div>-->

<!--        <div v-for="(category, key) in categories" v-bind:key="key" class="categories__content-item content__grid-item">-->
<!--            <a :href="categoryLink + '/' + category['id']" class="categories__content-link content__grid-link">-->
<!--                <h4 class="categories__content-title content__grid-title">{{category['name_' + locale] }}</h4>-->
<!--                <img v-if="category['coverBigThumb']" :src="category['coverBigThumb']" alt="" class="articles__content-image content__grid-image">-->
<!--                <p class="categories__content-text content__grid-text">{{ category['description_' + locale] }}</p>-->
<!--            </a>-->
<!--        </div>-->
<!--    </div>-->

    <div class="content__grid">
        <div
            v-for="(category, key) in categories"
            v-bind:key="key"
            class="content__grid-item"
        >
            <a
                :href="categoryLink + '/' + category['id']"
                class="content__grid-link"
            >
                <div
                    v-if="category['coverBigThumb']"
                    class="content__grid-image-wrap"
                    :style="{ 'background-image': 'url(' + category['coverBigThumb'] + ')' }"
                />
                <div
                    v-else
                    class="content__grid-image-wrap"
                    style="background-image: url('/images/icons/logo-square-icon.svg')"
                />
                <span class="content__grid-link-content">
                    <span class="content__grid-link-content-top">
                        <span class="content__grid-title">
                            {{category['name_' + locale] }}
                        </span>
                    </span>
                    <span class="content__grid-link-content-bottom">
                        <span class="content__grid-date">
                            2023/03
                        </span>
                        <span class="content__grid-link-icon-wrap">

                            <span class="content__grid-link-icon">
                                <template v-if="categoryType === 'article'">
                                    <svg class="content__grid-link-icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                         viewBox="0 0 52 52" style="enable-background:new 0 0 52 52;" xml:space="preserve">
                                        <rect x="0.9" y="38.3" width="9.3" height="2.7"/>
                                        <path d="M0.9,48c0,0.5,0.3,1,0.7,1h7.8c0.4,0,0.7-0.4,0.7-1v-6.1H0.9V48z"/>
                                        <rect x="0.9" y="14.1" width="9.3" height="23.3"/>
                                        <rect x="0.9" y="10.6" width="9.3" height="2.7"/>
                                        <path d="M9.4,3.1H1.6c-0.4,0-0.7,0.4-0.7,1v5.7h9.3V4C10.1,3.5,9.8,3.1,9.4,3.1z"/>
                                        <path d="M10.8,48c0,0.5,0.3,1,0.7,1h7.8c0.4,0,0.7-0.4,0.7-1v-6.1h-9.3V48z"/>
                                        <rect x="10.8" y="38.3" width="9.3" height="2.7"/>
                                        <rect x="10.8" y="14.1" width="9.3" height="23.3"/>
                                        <rect x="10.8" y="10.6" width="9.3" height="2.7"/>
                                        <path d="M19.4,3.1h-7.8c-0.4,0-0.7,0.4-0.7,1v5.7h9.3V4C20.1,3.5,19.8,3.1,19.4,3.1z"/>
                                        <rect x="20.8" y="38.3" width="9.3" height="2.7"/>
                                        <path d="M20.8,48c0,0.5,0.3,1,0.7,1h7.8c0.4,0,0.7-0.4,0.7-1v-6.1h-9.3V48z"/>
                                        <rect x="20.8" y="14.1" width="9.3" height="23.3"/>
                                        <rect x="20.8" y="10.6" width="9.3" height="2.7"/>
                                        <path d="M29.4,3.1h-7.8c-0.4,0-0.7,0.4-0.7,1v5.7h9.3V4C30.1,3.5,29.8,3.1,29.4,3.1z"/>
                                        <path d="M51.1,45.3l-1.6-5.8l-8.8,3.3l1.6,5.8c0.1,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.4,0.1,0.5,0.1l7.5-2.8
                                            c0.2-0.1,0.3-0.2,0.4-0.4C51.1,45.8,51.2,45.5,51.1,45.3z"/>
                                        <polygon points="48.6,36.1 39.8,39.3 40.5,41.9 49.3,38.7 	"/>
                                        <polygon points="42.3,13.1 33.5,16.3 39.5,38.5 48.4,35.3 	"/>
                                        <polygon points="41.4,9.7 32.5,12.9 33.3,15.5 42.1,12.3 	"/>
                                        <path d="M39.7,3.4c-0.1-0.3-0.2-0.5-0.4-0.6c-0.2-0.1-0.4-0.1-0.5-0.1l-7.5,2.8C31.1,5.6,31,5.8,30.9,6
                                            c0,0.1-0.1,0.2-0.1,0.4v0.1c0,0.1,0,0.2,0,0.3l1.5,5.4l8.8-3.3L39.7,3.4z"/>
                                    </svg>
                                </template>
                                <template v-if="categoryType === 'podcast'">
                                    <svg class="content__grid-link-icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                         viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                        <path d="M11,28.5c-0.1-1-0.9-1.7-1.7-1.6L8.4,27l1.8,18.4l0.6-0.1c0.9-0.1,1.5-1,1.4-2L11,28.5z" />
                                        <path d="M41,27.2c-0.9-0.1-1.6,0.6-1.7,1.6l-1.4,14.8c-0.1,1,0.5,1.9,1.4,2l0.6,0.1l1.7-18.4L41,27.2z" />
                                        <path d="M48.1,29.9c0.1-1-0.5-1.9-1.4-2l-0.9-0.1c0,0,0,0,0-0.1c0-13.3-9.3-24.1-20.8-24.1C13.7,3.7,4.4,14.3,4.3,27.4
                                            l-0.7,0.1c-0.9,0.1-1.5,1-1.4,2c0,0-2.8,6.1,1.4,15.6l0,0c0.3,0.6,0.9,1,1.5,0.9l4.2-0.5L7.5,27C7.9,16.2,15.6,7.4,25.1,7.4
                                            c9.6,0,17.4,8.9,17.5,20L41,45.8l4.1,0.5c0.9,0.1,1.6-0.6,1.7-1.6C50.2,37.2,48.1,29.9,48.1,29.9z" />
                                    </svg>
                                </template>
                                <template v-if="categoryType === 'video'">
                                    <svg class="content__grid-link-icon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                        <path class="st0" d="M42,5.9H8c-3.8,0-6.8,3.8-6.8,8.4v21.5c0,4.6,3.1,8.4,6.8,8.4H42c3.8,0,6.8-3.8,6.8-8.4V14.2
                                        C48.8,9.6,45.7,5.9,42,5.9z M33.4,25.7l-11.9,7.7c-0.5,0.3-1.3,0-1.3-0.7V17.3c0-0.6,0.7-1,1.3-0.7l11.9,7.7
                                        C33.9,24.6,33.9,25.4,33.4,25.7z" />
                                    </svg>
                                </template>
                            </span>
                        </span>
                    </span>
                </span>
            </a>
        </div>
    </div>

<!--    <div>-->
<!--        <div class="row">-->
<!--            <div v-for="(category, key) in categories" v-bind:key="key" class="col-md-4">-->
<!--                <a :href="categoryLink + '/' + category['id']">-->
<!--                <div class="mt-2 mb-2 p-4" style="border: solid 1px #1b508f">-->
<!--                    <h2>{{category['name_' + locale] }}</h2>-->
<!--                    <p>{{ category['description_' + locale] }}</p>-->
<!--                </div>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
    export default {
        mounted() {
            // console.log('Categories Component mounted.')
            // console.log('locale==='+this.locale)
            // console.log('link==='+this.categoryLink)
            // console.log('categories==='+JSON.stringify(this.categories))
        },
        props: {
            categories: {},
            locale: { type: String, default: 'en' },
            categoryLink: {},
            categoryType: {
                type: String,
                default: ''
            }
        },
        computed: {
            showAll () {
                if (this.locale === 'en') {
                    return 'SHOW ALL'
                } else {
                    return 'ALLE ANZEIGEN'
                }
            }
        },
    }
</script>
